<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                   <ion-button @click="$router.go(-1)">
                        <ion-img :src="require('@/assets/Icons/arrow-back-grey.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
                <ion-title><h1 class="text-base font-semibold text-center text-gray-400">About Us</h1></ion-title>
                <ion-buttons slot="end">
                    <ion-button router-link="/home/settings">
                        <ion-img :src="require('@/assets/Icons/swap-reject-circle-solid-grey.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <div class="flex flex-col h-full">
            <div class="mx-7.5 shadow-none pt-10">
                <h2 class="mb-2 font-bold text-md text-smcc-grey-dark">About SaveMoneyCutCarbon</h2>
                <p class="mb-4 text-md text-smcc-grey-dark">Why are we here, what do we do &amp; how do we help?</p>
                <p class="mb-4 text-md text-smcc-grey-dark">It’s simple: our promise is in our name. SaveMoneyCutCarbon makes it easy for people to save money – and the planet.</p>
                <ul class="mb-4 text-md text-smcc-grey-dark text-smcc-smcc-green list-outside">
                    <li class="ml-4 relative mb-2">We know that people are worried about the planet but don’t always know where to start or what to do.</li>
                    <li class="ml-4 relative mb-2">We know that people think it will cost them more to be green.</li>
                    <li class="ml-4 relative mb-2">We know you’re busy, that your time is precious &amp; that many see being green as a hassle.</li>
                </ul>
                <p class="text-md text-smcc-grey-dark">We make it easy.</p>
                <h2 class="mt-8 mb-2 font-bold text-md text-smcc-grey-dark">About Sustain Ability</h2>
                <p class="mb-4 text-md text-smcc-grey-dark">Every little change you make counts towards something bigger.</p>
                <p class="mb-4 text-md text-smcc-grey-dark">Our Sustain Ability app is designed to introduce you to everyday swaps that can help you and your home reduce its impact on the planet. Whether you're looking to use less plastic, reduce energy wastage, cut your water use or simply reduce your carbon footprint, we can help!</p>
                <p class="mb-4 text-md text-smcc-grey-dark">You'll only find the best brands in our range of products, which are designed to last. Work your way through the app and swipe right to create your very own sustainable swaps shopping list and watch your savings grow.</p>
            </div>
                <div class="shadow-inner h-4"></div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonTitle, IonToolbar, IonContent, IonButton, IonButtons, modalController, IonImg } from '@ionic/vue';
import {useRouter} from 'vue-router';
import TutorialModal from '@/components/TutorialModal.vue';
export default {
    name: 'ExploreContainer',
    props: {
        name: String
    },
    components: { IonPage, IonHeader, IonTitle, IonToolbar, IonContent, IonButton, IonButtons, IonImg },
    data() {
        return {
            user: []
        }
    },
    setup() {
        const openModal = async (id, type) => {
        const modal = await modalController.create({
            component: TutorialModal, //Modal is name of the component to render inside ionic modal
            componentProps: {
                id: id,
                type: type
            },
            swipeToClose: true,
            backdropDismiss: true,
        });
            return modal.present();
        };
        const router = useRouter();
        return {
            router,
            openModal
        };
    },
    methods: {
    },

}
</script>

<style scoped>
#container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#container strong {
    font-size: 20px;
    line-height: 26px;
}

#container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
}

#container a {
    text-decoration: none;
}

.button-inner{
    justify-content: space-between;
}
ul li::before {
    content : "\2022"; /* Adds the bullet */
    @apply text-smcc-smcc-green text-2xl -left-5 -top-2 absolute
}
/*! purgecss start ignore */

/*! purgecss end ignore */
</style>
